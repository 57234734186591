import { UserRole } from 'services/Utils';

export interface GetUserListResponse {
  [x: string]: any;
  readonly activated: number;
  readonly id: number;
  readonly username: string;
  readonly external_id: string;
  readonly internal_id: number;
  readonly remote_monitoring: string;
  readonly name: string;
  readonly last_name: string;
  readonly first_name: string;
  readonly role: string;
  readonly network_count?: number;
  readonly specialty?: string;
  readonly degree?: string;
  readonly group?: string;
  readonly 'providers.role'?: number;
  readonly alert_receiver?: number;
  readonly network_id?: number;
  readonly dob?: string;
  readonly imei?: number;
  readonly bodytrace_id?: string;
  readonly bodytrace_status?: string;
  readonly status_description?: string;
  readonly physical_caregem?: string;
  readonly physical_rpm?: string;
  readonly digital_cg_sign_date?: string;
  readonly digital_rpm_sign_date?: string;
  readonly consents?: Consent[];
}

export interface Consent {
  readonly consent_name: string;
  readonly remote_monitoring: number;
  readonly sign_date: string;
  readonly version: string;
}

export interface CreatePatientRequest {
  readonly address_zip: string;
  readonly cell: string;
  readonly dob: string;
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly home_addr_1: string;
  readonly home_addr_2: string;
  readonly home_tel: string;
  readonly state: string;
  readonly username: string;
  readonly drive_license_number?: string;
  readonly gender: string;
  readonly year_grad_med_school?: string;
}

export interface CarePlanResponse {
  name: string;
  id: number;
}

export interface CreatePhysicianRequest extends CreatePatientRequest {
  readonly group_name?: string;
  readonly specialty?: string;
  readonly degree?: string;
  readonly dea_number?: string;
  readonly npi?: string;
}

export interface User {
  readonly [parameter: string]: string | null;
}

export interface UpdateUserResponse {
  readonly authtoken: string;
  readonly user: User;
  readonly errorCode?: string;
  readonly existing_data?: User;
}

export interface UserDataResponse {
  readonly physical_caregem: number | null | string | boolean;
  readonly physical_rpm: number | null | string | boolean;
  readonly activated: number | null;
  readonly external_id: string;
  readonly id: number | null;
  readonly internal_id: number | null;
  readonly ref_uid?: string;
  readonly remote_monitoring: string;
  readonly username: string;
  readonly address_zip: string;
  readonly address_city: string;
  readonly email: string;
  readonly gender: string;
  readonly drive_license_number: string;
  readonly state: string;
  readonly home_addr_1: string;
  readonly home_addr_2: string;
  readonly home_tel: string;
  readonly role: string;
  readonly last_name: string;
  readonly dob: string;
  readonly first_name: string;
  readonly middle_name: string;
  readonly cell_country_code: string;
  readonly home_tel_country_code?: string;
  readonly office_tel_country_code?: string;
  readonly cell: string;
  readonly name: string;
  readonly device_pairing?: string;
  readonly start_date?: string;
  readonly org?: string;
  readonly ssn?: null;
  readonly npi?: string;
  readonly dea_number?: string;
  readonly degree?: string;
  readonly office_tel?: string;
  readonly year_grad_med_school?: string;
  readonly office_addr_1?: string;
  readonly office_addr_2?: string;
  readonly specialty?: string;
  readonly group?: string;
  readonly nursing_license_number?: string;
  readonly patient_network?: GetUserListResponse[];
  readonly mail_sent?: number;
  readonly program_id?: string;
}

export interface CallLogsResponse {
  readonly call_duration: number;
  readonly prv_name: string;
  readonly date_p: string;
  readonly call_type: string;
}
export interface CallRecordsResponse {
  readonly call_duration: number;
  readonly prv_name: string;
  readonly date_p: string;
  readonly desc: string;
  readonly notes: string;
}

export interface BillingLogsResponse {
  readonly code: string;
  readonly date_p: string;
  readonly desc: string;
  readonly patient: string;
  readonly prov: string;
  readonly call_duration: number;
  readonly bill_created_date: any;
  readonly bill_submitted_date: any;
  readonly bill_updated_date: any;
  readonly billing_status: string;
  readonly caregem_bill_reference: string;
  readonly cds_bill_reference: string;
  readonly icd_code: string;
}
export interface PatientDiagnosesResponse {
  readonly abbreviated_desc: string;
  readonly assigned_code: string;
}

export interface DiagnosisListResponse {
  readonly abbreviated_desc: string;
  readonly code: string;
  readonly category: string;
}

export interface Organization {
  readonly id: number;
  readonly name: string;
}
export interface NetworkResponse {
  readonly id: number;
  readonly username: string;
  readonly external_id: string;
  readonly internal_id: number;
  readonly remote_monitoring: string;
  readonly name: string;
  readonly role: string;
  readonly organizations: Organization[];
}

export interface CallRecordsResponse {
  readonly call_duration: number;
  readonly prv_name: string;
  readonly date_p: string;
  readonly desc: string;
  readonly notes: string;
}
export interface AddDiagnosisResponse {}

export interface DeleteDiagnosisResponse {}

export interface CreateUserResponse {
  readonly detail: {
    readonly errorCode?: string;
    readonly existing_data?: User;
  };
}
export interface UpdateAlertStatusRequest {
  readonly alert_receiver_status: number;
}

export interface UpdateAlertStatusResponse {}

export interface SuperAdminOrgListResponse {
  readonly id: number;
  readonly name: string;
}
export interface IMEIDetailsResponse {
  readonly active: string;
  readonly end_date: string;
  readonly imei: string;
  readonly patient_internal_id: string;
  readonly start_date: string;
}
interface PatientData {
  readonly id: number;
  readonly name: string;
}

export interface GetSuperAdminOrgData {
  readonly address: string;
  readonly city: string;
  readonly email: string;
  readonly id?: number;
  readonly name: string;
  readonly phone_1: string;
  readonly phone_2: string;
  readonly state: string;
  readonly zipcode: string;
}

export interface GetSuperAdminOrgCustomerAdminData {
  readonly id: string;
  readonly name: string;
}

export interface UpdateOrgDataResponse {}

export interface CreateNewOrgResponse {
  readonly id: number;
}

export interface CreateNewOrgResponse {
  readonly id: number;
}

export interface GetCustomerAdminDataResponse {
  readonly office_addr_1: string;
  readonly username: string;
  readonly office_tel: string;
  readonly office_addr_2: string;
  readonly cell: string;
  readonly address_city: string;
  readonly dob: string;
  readonly drive_license_number: string;
  readonly email: string;
  readonly first_name: string;
  readonly id: number;
  readonly is_read_only: number;
  readonly last_name: string;
  readonly org_id: string;
  readonly role: string;
  readonly gender: string;
  readonly ssn: null;
  readonly state: string;
  readonly address_zip: string;
}
export interface FormCustomerAdminDataPayload {
  readonly first_name: string;
  readonly last_name: string;
  readonly username: string;
  readonly dob: string;
  readonly office_tel: string;
  readonly cell: string;
  readonly office_addr_1: string;
  readonly office_addr_2: string;
  readonly address_city: string;
  readonly address_zip: string;
  readonly drive_license_number: string;
  readonly gender: string;
  readonly state: string;
  readonly is_read_only: string;
  readonly org_id: string;
  readonly email: string;
  readonly role: 'customer_admin';
  readonly id?: string;
}

export interface UpdateCustomerAdminResponse {
  id: string;
  name: string;
  username: string;
}
interface ParticipantData {
  readonly internal_id: number;
  readonly name: string;
  readonly role: string;
}

export interface MessageData {
  readonly content: string;
  readonly critical: boolean;
  readonly read: boolean;
  readonly sender_id: string;
  readonly timestamp: number;
}
export interface ChatData {
  readonly id: string;
  messages: MessageData[];
  readonly participants: ParticipantData[];
  readonly type: string;
}

export interface GetMessagesLogResponse {
  readonly chats: ChatData[];
  readonly patient: PatientData;
}

export interface RetrievedUserResponse {
  readonly id: number;
  readonly external_id: string;
  readonly role: string;
  readonly name: string;
}

export interface BillingProvidersResponse {
  readonly id: number;
  readonly activated: number;
  readonly name: string;
  readonly internal_id: number;
  readonly role: string;
  readonly group: string;
  readonly specialty: string;
  readonly remote_monitoring: string;
  readonly billing_permission: string;
}

interface PairedDeviceDetails {
  readonly imei: string;
  readonly start_date: string;
  readonly end_date: string;
}

interface RecordingDaysDetails {
  readonly start_date: string;
  readonly end_date: string;
  readonly number_of_days: number;
}

interface BillingDetails {
  readonly billing_charge_code: string;
  readonly date_of_service: string;
  readonly provider_name: string;
}

interface CallRecord {
  readonly [month: string]: number;
}

export interface RemoteMonitoringPatientDetails {
  readonly all_provider_name: string;
  readonly patient_name: string;
  readonly patient_last_name: string;
  readonly patient_internal_id: number;
  readonly date_of_service_99453: string | null;
  readonly creator_of_99453: string;
  readonly remote_monitoring: string;
  readonly paired_device_details: PairedDeviceDetails[];
  readonly call_records: CallRecord;
  readonly billing_details: BillingDetails[];
  readonly days_recording: RecordingDaysDetails[];
}

export interface AllPatientsRemoteMonitoringDetails {
  readonly patient_details: RemoteMonitoringPatientDetails[];
}

export interface ProvidersRemoteMonitoringDetails {
  readonly provider_name: string;
  readonly provider_id: number;
  readonly patient_details: RemoteMonitoringPatientDetails[];
}

export interface DeleteUserResponse {}

export interface UpdateNetworkResponse {}

interface Network {
  role?: UserRole;
  id?: string;
  degree?: string;
  name?: string;
}

export interface StateChange {
  readonly [parameter: string]: string | null | Network[] | Organization[];
}

export interface ChangeLogResponse {
  readonly version: string;
  readonly utc_timestamp: string;
  readonly external_id: string;
  readonly auth_id: number;
  readonly auth_ipv4: string;
  readonly auth_platform: string;
  readonly auth_role: string;
  readonly auth_org: number;
  readonly id: number;
  readonly auth_name: string;
  readonly auth_org_name: string;
  readonly updated_info: string[];
  readonly old_state: User;
  readonly new_state: User;
}

interface Changes {
  new: string | null;
  old: string | null;
}
export interface ChangeDict {
  readonly id: Changes;
  readonly name: Changes;
  readonly degree?: Changes;
  readonly role?: Changes;
}

interface PhoneNumber {
  readonly title: string;
  readonly number: string;
}

export interface UserData {
  readonly name: string;
  readonly userName: string;
  readonly firstName: string;
  readonly email: string;
  readonly lastName: string;
  readonly city: string;
  readonly zipCode: string;
  readonly cell: string;
  readonly role: string;
  readonly picture: string[];
  readonly phoneNumbers: PhoneNumber[];
  readonly userOrg: number;
  readonly remote_monitoring: string;
  readonly dbid: number;
  readonly internal_id: string;
  readonly billing_permission: string;
  readonly degree: string;
  readonly specialty: string;
  readonly is_read_only: boolean;
  readonly can_add_patient: boolean;
}

export interface ExceptionInputUserData {
  readonly first_name: string;
  readonly middle_name: string;
  readonly last_name: string;
  readonly gender: string;
  readonly email: string;
  readonly role: string;
  readonly address_city: string;
  readonly state: string;
  readonly address_zip: string;
  readonly dob: string;
  readonly cell: string;
  readonly home_tel: string;
  readonly home_addr_1: string;
  readonly home_addr_2: string;
  readonly ref_uid: string;
  readonly ssn: string;
  readonly device: string;
  readonly remote_monitoring: 'Y' | 'N';
  readonly org_id: string;
  readonly [key: string]: string;
}

export enum ExceptionStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  ARCHIVED = 'ARCHIVED',
}
export interface ExceptionMatchingData {
  readonly address_zip: string;
  readonly address_city: string;
  readonly email: string;
  readonly gender: 'F' | 'M';
  readonly drive_license_number: string;
  readonly ssn: string;
  readonly state: string;
  readonly home_addr_1: string;
  readonly home_addr_2: string;
  readonly home_tel: string;
  readonly role: string;
  readonly last_name: string;
  readonly first_name: string;
  readonly external_id: string;
  readonly username: string;
  readonly cell: string;
  readonly matching_org_id: number;
  readonly exception_id: string;
  readonly status: ExceptionStatus;
  readonly dob: string;
  readonly matching_fields: string;
  readonly [key: string]: string | number;
}

export interface ExceptionsListData {
  readonly index: string;
  readonly user_data: ExceptionInputUserData;
  readonly matching_data: ExceptionMatchingData[];
}

export interface CreateUserInExceptionResponse {
  readonly id: number;
  readonly last_name: string;
  readonly name: string;
  readonly role: string;
  readonly username: string;
}

export interface ChannelUserInfo {
  readonly address_zip: string;
  readonly address_city: string;
  readonly email: string;
  readonly gender: string;
  readonly drive_license_number: string;
  readonly ssn: string;
  readonly state: string;
  readonly home_addr_1: string;
  readonly home_addr_2: string;
  readonly home_tel: string;
  readonly role: string;
  readonly last_name: string;
  readonly dob: string;
  readonly first_name: string;
  readonly external_id: string;
  readonly username: string;
  readonly cell: string;
  readonly npi: string;
  readonly dea_number: string;
  readonly office_tel: string;
  readonly year_grad_med_school: string;
  readonly office_addr_1: string;
  readonly office_addr_2: string;
  readonly degree: string;
  readonly specialty: string;
  readonly internal_id: string;
}

export interface UserInfoMap {
  [key: string]: ChannelUserInfo;
}

export interface ChannelData {
  readonly channel_name: string;
  readonly channel_arn: string;
  readonly is_patient_enabled: number;
  readonly is_patient_linked_channel: number;
  readonly pat_uname: string;
  readonly user1_uname: string;
  readonly user2_uname: string;
  readonly latest_message: string;
  readonly latest_message_timestamp: string;
}

export interface GetUserChannelsResponse {
  readonly user_info: UserInfoMap;
  readonly channel_arns: ChannelData[];
}

export interface ChannelMessageData {
  readonly latest_message: string;
  readonly latest_message_timestamp: string;
  readonly Sender: string;
}

export interface ChannelMessagesResponse {
  readonly next_token: string;
  readonly channel_arn: string;
  readonly messages: ChannelMessageData[];
}

export interface PatientConsentRecords {
  readonly consent_path: string;
  readonly consent_name: string;
  readonly version: number;
  readonly sign_date: string;
  readonly valid_upto: string;
  readonly remote_monitering: number;
  readonly id?: number;
}

export interface DeviceHistoryRecords {
  readonly bodytrace_id: string;
  readonly created_date: string;
  readonly imei: string;
  readonly patient_internal_id: number;
  readonly status: string;
  readonly updated_date: string;
}

export interface ConsentPdfResponse {
  readonly pdfByteStr: string;
}

export enum BillingReportsStatus {
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  SUBMISSION_ERROR = 'Submission Error',
}

export interface SetThresholdBody {
  readonly lower_systolic: number;
  readonly upper_systolic: number;
  readonly lower_diastolic: number;
  readonly upper_diastolic: number;
}
