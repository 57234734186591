import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  Breakpoint,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  GetUserListResponse,
  NetworkResponse,
  UpdateAlertStatusRequest,
  UserDataResponse,
} from 'api/SharedTypes';
import { FormFieldType, Icons, SubmitFormData, UserRole } from 'services/Utils';
import { FormFieldObject } from 'components/UserForm';
import { UserFormFields } from 'components/UserFormFields';
import './UpdateDialog.css';
import { NetworkInfo } from 'components/NetworkInfo';
import {
  Message,
  ArrowDropDown,
  Call,
  PhoneCallback,
  ChangeCircle,
  Assignment,
} from '@mui/icons-material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useIntl } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { Link, To } from 'react-router-dom';
import { ClinicalDiagnosis } from 'components/ClinicalDiagnosis';
import { createApiClient } from 'api/apiClient';
import { NavRoutes } from 'pages/NavRouter';
import { green, grey } from '@mui/material/colors';
import {
  getIsDOBInvalid,
  validateEmail,
  validateName,
} from '../../services/utilsFunctions';
import noop from 'lodash/noop';
import { useMutation } from 'react-query';
import { UserContextState, UserContext } from 'contexts/userContext';
import ConsentStatus from 'components/ConsentStatus';
import DeviceAssignment from 'components/DeviceAssignment';
import { DateTime } from 'luxon';
import { usePatientData } from 'pages/Patients/usePatientData';

interface UpdateDialogProps {
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly currentUserData: UserDataResponse;
  readonly role: UserRole;
  readonly formFields: FormFieldObject;
  readonly handleUpdate: () => void;
  readonly handleDevicePairing?: () => void;
  readonly handleNetworkMemberAlertStatusUpdate?: (
    networkMemberId: number,
    patientId: number,
    status: UpdateAlertStatusRequest,
  ) => void;
  readonly handleRemoteMonitoring?: (
    patientNetwork: GetUserListResponse[],
  ) => void;
  readonly remoteMonitoring?: string;
  readonly showSavingStatus?: boolean;
  readonly userDataLoading: boolean;
  readonly isUserDataUpdating: boolean;
  readonly getPatientNetworkList?: (id: number) => void;
  readonly isPatientNetworkDataLoading?: boolean;
  readonly patientNetwork?: GetUserListResponse[];
  readonly currentTab?: string;
  readonly physical_caregem?: string | undefined | null;
  readonly physical_rpm?: string | undefined | null;
  readonly digital_cg_sign_date?: boolean | number | string;
  readonly digital_rpm_sign_date?: boolean | number | string;
  readonly physicalCaregem?: string | null | number | boolean;
  readonly physicalRPM?: string | null | number | boolean;
  readonly onConsentChange?: (
    caregemConsent: string | null,
    rpmConsent: string | null,
  ) => void;
  readonly handleUpdateParent?: () => void;
  readonly checkCaregemPhysicalConsent?: (flag: any) => void;
  readonly checkRpmPhysicalConsent?: (flag: any) => void;
  readonly isCaregemPhysicalConsentChecked?: any;
  readonly isRpmPhysicalConsentChecked?: any;
  readonly getPatientList?: (flag: any) => void;
}

export enum TabName {
  userInfo = 'userInfo',
  networkInfo = 'networkInfo',
  clinicalDiagnosis = 'clinicalDiagnosis',
  logs = 'logs',
  consentStatus = 'consentStatus',
}

interface LogOption {
  readonly id: number;
  readonly label: string;
  readonly icon: JSX.Element;
  readonly handleClick: () => void;
  readonly handleConfirm?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
  readonly url?: To;
}

interface TabPanelProps {
  readonly title: string;
  readonly value: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  title,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      className="tabPanel"
      hidden={value !== title}
      id={`simple-tabpanel-${title}`}
      aria-labelledby={`simple-tab-${title}`}
      {...other}
    >
      {children}
    </div>
  );
};

export const TabContext = createContext(false);

function a11yProps(title: string) {
  return {
    id: `simple-tab-${title}`,
    'aria-controls': `simple-tabpanel-${title}`,
  };
}

export const UpdateDialog: React.FC<UpdateDialogProps> = ({
  physical_caregem,
  physical_rpm,
  digital_cg_sign_date,
  digital_rpm_sign_date,
  physicalCaregem,
  physicalRPM,
  onConsentChange,
  handleUpdateParent,
  open,
  handleClose,
  currentUserData,
  role,
  formFields,
  handleUpdate,
  handleDevicePairing,
  handleNetworkMemberAlertStatusUpdate,
  handleRemoteMonitoring,
  remoteMonitoring,
  showSavingStatus,
  userDataLoading,
  isUserDataUpdating,
  getPatientNetworkList,
  isPatientNetworkDataLoading,
  patientNetwork,
  currentTab,
  checkCaregemPhysicalConsent,
  checkRpmPhysicalConsent,
  isCaregemPhysicalConsentChecked,
  isRpmPhysicalConsentChecked,
  getPatientList,
}) => {
  const {
    patientUserData,
    patientFormActions,
    patientFormData,
    setPatientFormData,
    getFormSubmitData,
    putUpdateFormData,
    clearForm,
  } = usePatientData();
  const { isReadOnly, userData } = useContext<UserContextState>(UserContext);
  const [tab, setTab] = useState<TabName>(TabName.userInfo);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [networkData, setNetworkData] = useState<NetworkResponse[]>([]);
  const [loadingNetwork, setLoadingNetwork] = useState<boolean>(false);
  const [isDeviceAssignmentClicked, setIsDeviceAssignmentClicked] =
    useState<boolean>(false);
  const [normalId, setNormalId] = useState<number | null>(currentUserData.id);

  const [physicalCaregemConsentC, setPhysicalCaregemConsentC] = useState<
    string | null
  >(isCaregemPhysicalConsentChecked);
  const [physicalRPMConsentC, setPhysicalRPMConsentC] = useState<string | null>(
    isRpmPhysicalConsentChecked,
  );

  const handleUpdateChild = () => {
    if (onConsentChange && handleUpdateParent) {
      onConsentChange(physicalCaregemConsentC, physicalRPMConsentC);
    }
  };

  useEffect(() => {
    checkCaregemPhysicalConsent && checkCaregemPhysicalConsent(false);
    checkRpmPhysicalConsent && checkRpmPhysicalConsent(false);
  }, [open]);

  const accessToken = localStorage.getItem('access_token');
  const apiClient = createApiClient(accessToken!, new AbortController());

  // const { isLoading: isUpdatingPatientData, mutate: updatePatientData } =
  //   useMutation(
  //     'update-patient-data',
  //     async (params: { details: SubmitFormData; id: number }) =>
  //       apiClient.updatePatientData(params.details, params.id),
  //     {
  //       onSuccess: (response: any) => {
  //         // console.log(response);
  //       },
  //     },
  //   );

  // const updatePatientCheckBox = async () => {
  //   const data = putUpdateFormData(currentUserData.id);
  //   updatePatientData({
  //     details: {
  //       ...data,
  //       physical_caregem: physicalCaregemConsentC,
  //       physical_rpm: physicalRPMConsentC,
  //     },
  //     id: currentUserData.id!,
  //   });
  // };

  const { isLoading: isPrvNetworkDataLoading, mutate: getNetworkList } =
    useMutation(
      'get-network-list',
      ({ id, role }: { id: string; role: UserRole }) =>
        apiClient.getNetworkList(id, role),
      {
        onSuccess: response => {
          setNetworkData(response);
        },
      },
    );

  const { isLoading: isSendingInvitation, mutate: resendInvitation } =
    useMutation('resend-invitation', async () =>
      apiClient.resendInvitation(currentUserData.internal_id as number),
    );

  const networkInfo = (id: number, role: UserRole) =>
    getNetworkList({
      id: id.toString(),
      role:
        role === UserRole.caregiver ? UserRole.caregiver : UserRole.provider,
    });

  const intl = useIntl();
  // console.log(
  //   'LALALALALALALALALLALALALALALAL',
  //   physical_caregem,
  //   physical_rpm,
  //   digital_cg_sign_date,
  //   digital_rpm_sign_date,
  // );

  const logOptions: LogOption[] = [
    {
      id: 0,
      label: 'Messages Log',
      handleClick: () => {
        handleCloseUserMenu();
      },
      icon: <Message fontSize="medium" />,
      url: `${NavRoutes.messagesLog}/${currentUserData.id}`,
      handleConfirm: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        localStorage.setItem(
          'patient_message_log_username',
          currentUserData.username,
        );
        if (
          !window.confirm(
            "Warning!!!\n\nThis Information is Protected Health Information containing sensitive communications, with restricted access regulated by HIPAA. You may only access this if you are authorized to do so by your organization.\r\nPlease note this information may only be accessed when requested by patient, or patient's legal representative, or for Treatment, Payment, or Health Care Operations, as defined in the HIPAA Privacy rule under 45 CFR 164.501, by  a member of the patient's provider network, or by your organization. By proceeding, you indicate that you meet these guidelines for access to this information.",
          )
        ) {
          localStorage.setItem('patient_message_log_username', '');
          event.preventDefault();
        }
      },
    },
    {
      id: 1,
      label: 'Call Logs',
      handleClick: () => {
        handleCloseUserMenu();
      },
      handleConfirm: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        if (!window.confirm('This will open in a new page. Proceed?')) {
          event.preventDefault();
        }
      },
      icon: <Call fontSize="medium" />,
      url: `${NavRoutes.callLogs}/${currentUserData.id}/${currentUserData.internal_id}`,
    },
    {
      id: 2,
      label: 'Call Records',
      handleClick: () => {
        handleCloseUserMenu();
      },
      handleConfirm: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        if (!window.confirm('This will open in a new page. Proceed?')) {
          event.preventDefault();
        }
      },
      icon: <PhoneCallback fontSize="medium" />,
      url: `${NavRoutes.callRecords}/${currentUserData.id}/${currentUserData.internal_id}`,
    },
    {
      id: 3,
      label: 'Changes Log',
      handleClick: () => {
        handleCloseUserMenu();
      },
      handleConfirm: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      ) => {
        if (
          !window.confirm(
            "Warning!!!\n\nThis Information is Protected Health Information containing sensitive communications, with restricted access regulated by HIPAA. You may only access this if you are authorized to do so by your organization.\r\nPlease note this information may only be accessed when requested by patient, or patient's legal representative, or for Treatment, Payment, or Health Care Operations, as defined in the HIPAA Privacy rule under 45 CFR 164.501, by  a member of the patient's provider network, or by your organization. By proceeding, you indicate that you meet these guidelines for access to this information.",
          )
        ) {
          event.preventDefault();
        }
      },
      icon: <ChangeCircle fontSize="medium" />,
      url: `${NavRoutes.changesLog}/${currentUserData.role}/${currentUserData.internal_id}`,
    },
    {
      id: 4,
      label: 'Device Assignment',
      handleClick: () => {
        handleDesviceAssigment();
        handleCloseUserMenu();
      },
      icon: <Assignment fontSize="medium" />,
      url: '',
    },
  ];

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDesviceAssigment = () => {
    setIsDeviceAssignmentClicked(true);
    setTab(TabName.logs);
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const isSubmitDisabled = () => {
    const requiredFields = Object.keys(formFields).filter(
      field => formFields[field].isRequired,
    );
    const isRequiredFilled = requiredFields.map(field => {
      if (formFields[field].type === FormFieldType.date) {
        if (!formFields[field].dateValue) {
          return true;
        }
        if (
          formFields[field].dateValue &&
          formFields[field].dateValue?.invalidReason
        ) {
          return true;
        }
        return false;
      }
      if (field === 'email') {
        if (formFields[field].value) {
          if (validateEmail(formFields[field].value || '')) {
            return false;
          }
        }
        return true;
      }

      if (field === 'first_name' || field === 'last_name') {
        if (formFields[field].value) {
          if (validateName(formFields[field].value || '')) {
            return false;
          }
        }
        return true;
      }

      return formFields[field].value ? false : true;
    });

    const invalidMiddleName =
      formFields['middle_name'] && formFields['middle_name'].value
        ? !validateName(formFields['middle_name'].value || '')
        : false;

    const isDOBRequired = formFields['dob']?.isRequired || false;
    const isDOBInvalid = getIsDOBInvalid(
      formFields['dob']?.dateValue,
      isDOBRequired,
    );
    return isRequiredFilled.reduce((acc, nextValue) => acc || nextValue) ||
      isUserDataUpdating ||
      isReadOnly === false
      ? false
      : isReadOnly && userData?.can_add_patient === true
      ? false
      : !userData?.can_add_patient || isDOBInvalid || invalidMiddleName;
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabName) => {
    setIsDeviceAssignmentClicked(false);
    if (newValue === TabName.logs) {
      setTab(TabName.logs);
      return;
    } else {
      setTab(newValue);
      setNetworkData([]);
      setLoadingNetwork(false);

      if (
        newValue === TabName.networkInfo &&
        (role === UserRole.physician ||
          role === UserRole.caregiver ||
          role === UserRole.case_manager ||
          role === UserRole.nurse)
      ) {
        setNetworkData([]);
        networkInfo(currentUserData.id!, role);
        role === UserRole.caregiver && setLoadingNetwork(true);
      } else if (
        newValue === TabName.networkInfo &&
        role === UserRole.patient
      ) {
        getPatientNetworkList?.(currentUserData.id!);
        setLoadingNetwork(true);
      }
    }
  };

  const handleDialogClose = () => {
    setTab(TabName.userInfo);
    handleClose();
    setLoadingNetwork(false);
    setNetworkData([]);
  };

  let fullWidth = false;
  let maxWidth: Breakpoint = 'sm';

  if (currentTab === 'EditPatient' && tab === TabName.consentStatus) {
    fullWidth = true;
    maxWidth = 'lg';
  }

  if (currentTab === 'EditPatient' && tab !== TabName.consentStatus) {
    fullWidth = true;
    maxWidth = 'lg';
  }

  const checkPatientNetwork = (patientNetwork: GetUserListResponse[]) => {
    if (patientNetwork.length === 0) {
      if (
        window.confirm(
          'To modify remote monitoring you must add a provider to the network!',
        )
      ) {
        return null;
      }
    } else {
      handleDevicePairing?.();
    }
  };

  // useEffect(() => {
  //   if (physical_caregem != null && digital_cg_sign_date == null) {
  //     setCGConsentForm(true);
  //   }
  //   if (physical_rpm != null && digital_rpm_sign_date == null) {
  //     setRPMConsentForm(true);
  //   }
  // }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>
          {currentUserData.name ||
            `${currentUserData.first_name} ${currentUserData.last_name}`}
        </DialogTitle>

        {userDataLoading ? (
          <div className="centerLoading">
            <CircularProgress />
          </div>
        ) : (
          <DialogContent>
            <Tabs value={tab} centered onChange={handleTabChange}>
              <Tab
                label={`${role.toUpperCase()} INFO`}
                value={TabName.userInfo}
                {...a11yProps(TabName.userInfo)}
              />
              <Tab
                label="NETWORK INFO"
                value={TabName.networkInfo}
                {...a11yProps(TabName.networkInfo)}
              />
              {role === UserRole.patient ? (
                <Tab
                  label="CLINICAL DIAGNOSES"
                  value={TabName.clinicalDiagnosis}
                  {...a11yProps(TabName.clinicalDiagnosis)}
                />
              ) : null}
              {role === UserRole.patient ? (
                <Tab
                  label="CONSENT STATUS"
                  value={TabName.consentStatus}
                  {...a11yProps(TabName.consentStatus)}
                />
              ) : null}
              <Tab
                value={TabName.logs}
                disableRipple={false}
                label={
                  <div>
                    <div color="inherit" onClick={handleOpenUserMenu}>
                      {intl.formatMessage({ id: I18nKey.LOGS })}
                      <ArrowDropDown fontSize="large" />
                    </div>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {role === UserRole.patient
                        ? logOptions.map(option => (
                            <MenuItem
                              key={option.id}
                              onClick={option.handleClick}
                            >
                              <ListItemIcon>{option.icon}</ListItemIcon>
                              <Link
                                to={option?.url || ''}
                                onClick={option?.handleConfirm}
                                target={option.id == 4 ? '' : '_blank'}
                              >
                                <ListItemText>{option.label}</ListItemText>
                              </Link>
                            </MenuItem>
                          ))
                        : logOptions
                            .filter(option => option.label === 'Changes Log')
                            .map(option => (
                              <MenuItem
                                key={option.id}
                                onClick={option.handleClick}
                              >
                                <ListItemIcon>{option.icon}</ListItemIcon>
                                <Link
                                  to={option?.url || ''}
                                  onClick={option?.handleConfirm}
                                  target="_blank"
                                >
                                  <ListItemText>{option.label}</ListItemText>
                                </Link>
                              </MenuItem>
                            ))}
                    </Menu>
                  </div>
                }
              />
            </Tabs>
            <TabPanel value={tab} title={TabName.userInfo}>
              <UserFormFields formFields={formFields} />
            </TabPanel>
            <TabPanel value={tab} title={TabName.networkInfo}>
              {role === UserRole.patient ? (
                <TabContext.Provider value={loadingNetwork}>
                  <NetworkInfo
                    userData={currentUserData}
                    isLoading={isPatientNetworkDataLoading!}
                    handleNetworkMemberAlertStatusUpdate={
                      handleNetworkMemberAlertStatusUpdate
                    }
                    role={UserRole.patient}
                    patientNetwork={patientNetwork}
                    patientNetworkRefresh={getPatientNetworkList}
                  />
                </TabContext.Provider>
              ) : (
                <TabContext.Provider value={loadingNetwork}>
                  <NetworkInfo
                    userData={currentUserData}
                    networkData={networkData}
                    isLoading={isPrvNetworkDataLoading}
                    role={UserRole.provider}
                    refreshNetworkList={networkInfo}
                  />
                </TabContext.Provider>
              )}
            </TabPanel>
            <TabPanel value={tab} title={TabName.clinicalDiagnosis}>
              <ClinicalDiagnosis
                patientInternalId={currentUserData.internal_id!}
                tabName={tab}
                userRole={currentUserData.role}
              />
            </TabPanel>
            <TabPanel value={tab} title={TabName.consentStatus}>
              <ConsentStatus
                patientId={currentUserData.internal_id as number}
                userData={currentUserData}
                handleDialogClose={handleDialogClose}
                CGConsentForm={digital_cg_sign_date}
                RPMConsentForm={digital_rpm_sign_date}
                getPatientList={getPatientList}
              />
            </TabPanel>
            <TabPanel value={tab} title={TabName.logs}>
              {isDeviceAssignmentClicked && (
                <DeviceAssignment
                  patientId={currentUserData.internal_id as number}
                />
              )}
            </TabPanel>
          </DialogContent>
        )}

        <DialogActions>
          {tab === TabName.userInfo ? (
            <ButtonGroup>
              {role === UserRole.patient && (
                <>
                  {physical_caregem === null &&
                    digital_cg_sign_date === null && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="success"
                              checked={isCaregemPhysicalConsentChecked === '1'}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  checkCaregemPhysicalConsent &&
                                    checkCaregemPhysicalConsent('1');
                                } else {
                                  checkCaregemPhysicalConsent &&
                                    checkCaregemPhysicalConsent(null);
                                }
                              }}
                            />
                          }
                          label="Physical Caregem Consent"
                        />
                      </FormGroup>
                    )}

                  {physical_rpm === null && digital_rpm_sign_date === null && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="secondary"
                            checked={isRpmPhysicalConsentChecked === '1'}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                checkRpmPhysicalConsent &&
                                  checkRpmPhysicalConsent('1');
                              } else {
                                checkRpmPhysicalConsent &&
                                  checkRpmPhysicalConsent(null);
                              }
                            }}
                          />
                        }
                        label="Physical RPM Consent"
                      />
                    </FormGroup>
                  )}

                  {physical_caregem !== null &&
                    digital_cg_sign_date !== 'NA' && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="success"
                              checked
                              disabled
                            />
                          }
                          label="Physical Caregem Consent"
                        />
                      </FormGroup>
                    )}

                  {physical_rpm !== null && digital_rpm_sign_date !== 'NA' && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="secondary"
                            checked
                            disabled
                          />
                        }
                        label="Physical RPM Consent"
                      />
                    </FormGroup>
                  )}
                </>
              )}
              {role !== UserRole.caregiver && (
                <ButtonGroup>
                  {/* {physical_caregem === null &&
                    digital_cg_sign_date === null && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="success"
                              checked={physicalCaregemConsentC === '1'}
                              onChange={handlePhysicalCaregemConsentChange}
                            />
                          }
                          label="Physical Caregem Consent"
                        />
                      </FormGroup>
                    )}

                  {physical_rpm === null && digital_rpm_sign_date === null && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="secondary"
                            checked={physicalRPMConsentC === '1'}
                            onChange={handlePhysicalRPMConsentChange}
                          />
                        }
                        label="Physical RPM Consent"
                      />
                    </FormGroup>
                  )}

                  {physical_caregem !== null &&
                    digital_cg_sign_date !== 'NA' && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="success"
                              checked
                              disabled
                            />
                          }
                          label="Physical Caregem Consent"
                        />
                      </FormGroup>
                    )}

                  {physical_rpm !== null && digital_rpm_sign_date !== 'NA' && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            color="secondary"
                            checked
                            disabled
                          />
                        }
                        label="Physical RPM Consent"
                      />
                    </FormGroup>
                  )} */}

                  {role === UserRole.patient &&
                    (remoteMonitoring === 'N' || remoteMonitoring === '') && (
                      <ListItemButton
                        onClick={() => {
                          if (
                            sessionStorage.getItem('deviceAssignment') ===
                            'Automated'
                          ) {
                            handleRemoteMonitoring?.(patientNetwork!);
                          } else {
                            checkPatientNetwork(patientNetwork!);
                          }
                        }}
                        disabled={
                          isReadOnly === false
                            ? false
                            : isReadOnly && userData?.can_add_patient === true
                            ? false
                            : !userData?.can_add_patient
                        }
                      >
                        <Icon
                          sx={{
                            color: grey[500],
                          }}
                        >
                          {Icons.settings_remote}
                        </Icon>
                      </ListItemButton>
                    )}

                  {role === UserRole.patient &&
                    remoteMonitoring !== 'N' &&
                    remoteMonitoring !== '' && (
                      <Icon
                        sx={{
                          color:
                            remoteMonitoring === 'Y'
                              ? green[500]
                              : remoteMonitoring == null
                              ? grey[500]
                              : '#f2d720',
                          marginRight: '16px',
                          marginTop: '6px',
                        }}
                      >
                        {Icons.settings_remote}
                      </Icon>
                    )}

                  {(role === UserRole.physician ||
                    role === UserRole.nurse ||
                    role === UserRole.case_manager) &&
                    (remoteMonitoring === 'N' || remoteMonitoring === '') && (
                      <ListItemButton
                        onClick={() => {
                          handleRemoteMonitoring?.(patientNetwork!);
                        }}
                      >
                        <Icon
                          sx={{
                            color: grey[500],
                          }}
                        >
                          {Icons.settings_remote}
                        </Icon>
                      </ListItemButton>
                    )}

                  {(role === UserRole.physician ||
                    role === UserRole.nurse ||
                    role === UserRole.case_manager) &&
                    remoteMonitoring !== 'N' &&
                    remoteMonitoring !== '' && (
                      <ListItemButton
                        onClick={() => {
                          handleRemoteMonitoring?.(patientNetwork!);
                        }}
                      >
                        <Icon
                          sx={{
                            color:
                              remoteMonitoring === 'Y'
                                ? green[500]
                                : remoteMonitoring == null
                                ? grey[500]
                                : '#f2d720',
                          }}
                        >
                          {Icons.settings_remote}
                        </Icon>
                      </ListItemButton>
                    )}

                  {role === UserRole.patient &&
                    currentUserData.mail_sent !== 1 && (
                      <Button
                        variant="contained"
                        disabled={isSubmitDisabled()}
                        sx={{ marginRight: 1 }}
                        onClick={() => resendInvitation()}
                      >
                        {isSendingInvitation ? (
                          <CircularProgress
                            size={30}
                            style={{
                              color: 'white',
                            }}
                          />
                        ) : (
                          intl.formatMessage({
                            id: I18nKey.SEND_INVITATION,
                          })
                        )}
                      </Button>
                    )}

                  {role === UserRole.patient &&
                    currentUserData.device_pairing && (
                      <Button
                        variant="contained"
                        disabled={isSubmitDisabled()}
                        sx={{ marginRight: 1 }}
                        onClick={handleDevicePairing}
                      >
                        {intl.formatMessage({
                          id: I18nKey.PATIENT_STOP_PAIRING,
                        })}
                      </Button>
                    )}
                </ButtonGroup>
              )}

              <Button
                variant="contained"
                disabled={isSubmitDisabled()}
                onClick={() => {
                  handleUpdateChild();
                  handleUpdate();
                }}
              >
                {isUserDataUpdating ? (
                  <CircularProgress size={30} />
                ) : (
                  intl.formatMessage({
                    id: I18nKey.UPDATE,
                  })
                )}
              </Button>
            </ButtonGroup>
          ) : null}

          <Button onClick={showSavingStatus ? noop : handleDialogClose}>
            {showSavingStatus
              ? 'Saving...'
              : intl.formatMessage({ id: I18nKey.CANCEL })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
