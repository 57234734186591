import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { getInitialPath } from 'components/CaregemNavBar';
import NavRouter from 'pages/NavRouter';
import Login from 'pages/Login';
import RequestChangePassword from 'pages/RequestChangePassword';
import { createApiClient } from 'api/apiClient';
import { UserContext, UserContextState } from 'contexts/userContext';
import { CircularProgress } from '@mui/material';

export interface JWTToken {
  readonly auth_time: number;
  readonly client_id: string;
  readonly 'cognito:groups': string[];
  readonly event_id: string;
  readonly exp: number;
  readonly iat: number;
  readonly iss: string;
  readonly jti: string;
  readonly origin_jti: string;
  readonly scope: string;
  readonly sub: string;
  readonly token_use: string;
  readonly username: string;
}

const App: React.FC = () => {
  const userContext = useContext<UserContextState>(UserContext);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [authDataLoading, setAuthDataLoading] = useState<boolean>(false);
  const [authDataError, setAuthDataError] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem('access_token');
  useEffect(() => {
    const controller = new AbortController();
    if (accessToken) {
      const decoded: JWTToken = jwtDecode(accessToken!);
      if (
        (location.pathname.includes('billingReports') ||
          location.pathname.includes('remoteMonitoringReports')) &&
        decoded.exp < DateTime.now().toMillis()
      ) {
        setAuthenticated(true);
      }

      if (DateTime.now().toMillis() < decoded.exp * 1000) {
        setAuthDataLoading(true);
        createApiClient(accessToken, controller)
          .getLoggedInUserData()
          .then(userData => {
            if (userData === undefined) {
              setAuthDataLoading(false);
              setAuthDataError(
                'Failed to fetch user data. Redirecting to login page',
              );
              return;
            }
            userContext.setLoggedInUserData(userData);
            userContext.setIsReadOnlyUser(userData.is_read_only);
            if (userData.role === 'customer_admin') {
              setAuthenticated(true);
              setUserName(decoded.username);
              setAuthDataLoading(false);
              setAuthDataError('');
              navigate(getInitialPath(window.location.pathname));
              sessionStorage.setItem('loggedInUser', decoded.username);
            } else {
              setAuthenticated(false);
              setAuthDataLoading(false);
              setAuthDataError('');
              localStorage.clear();
              navigate('/');
            }
          })
          .catch(() => {
            setAuthDataLoading(false);
            setAuthDataError(
              'Failed to fetch user data. Redirecting to login page',
            );
          });
      } else {
        setAuthenticated(false);
        localStorage.clear();
        navigate('/');
      }
    }
    return () => {
      controller.abort();
    };
  }, [accessToken]);

  useEffect(() => {
    if (authDataError) {
      setTimeout(() => {
        setAuthenticated(false);
        localStorage.clear();
        setAuthDataError('');
      }, 1000);
    }
  }, [authDataError]);

  const logOutUser = () => {
    localStorage.clear();
    setAuthenticated(false);
    navigate('/');
  };

  return isAuthenticated ? (
    <NavRouter handleLogout={logOutUser} username={userName} />
  ) : resetPage ? (
    <RequestChangePassword setResetPage={setResetPage} />
  ) : authDataLoading ? (
    <div style={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
      <CircularProgress />
    </div>
  ) : authDataError ? (
    <div style={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
      <div className="red">{authDataError}</div>
    </div>
  ) : (
    <Login setAuthenticated={setAuthenticated} setResetPage={setResetPage} />
  );
};

export default App;
